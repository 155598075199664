header {
  display: flex;
  padding: 0.5rem 0.8rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary-color);

  height: max-content;
}

header > section {
  width: 100%;
  margin: 0;
  padding: 0;
}

#header-logo {
  border: 0;
  padding: 0;
  background-color: transparent;
}

#right {
  display: flex;
  justify-content: end;
}

#logo {
  height: 2rem;
}
