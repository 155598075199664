* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --primary-color: #0a2031;
  --secondary-color: #031a30;
  --accent-color: #70707088;
  --text-color: #e5dfde;

  font-size: 16px;

  /* --text-size: clamp(1rem, 1.2vw, 1.4rem); */
  /* --heading-2-size: clamp(1.5rem, 1.8vw, 2rem); */
}

html,
body {
  background-color: var(--primary-color);
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon {
  fill: var(--text-color);
}

a,
a:visited {
  color: var(--text-color);
}

button:not(:disabled) {
  cursor: pointer;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: max-content;
  max-width: 100%;
}

h2 {
  font-size: var(--heading-2-size);
}
p,
a {
  font-size: var(--text-size);
}

@media only screen and (max-width: 575px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) {
  :root {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  :root {
    font-size: 20px;
  }
}
