#console-list-wrapper {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow: auto;
}

#console-list {
  display: flex;
  flex-direction: column;

  width: 100%;

  font-family: 'Courier New', Courier, monospace;
}

.console-entry {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cli-user {
  color: green;
}

.location {
  color: orange;
}

.arrow-right {
  scale: 0.8;
}
