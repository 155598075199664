.std-button {
  padding: 0.6rem 1rem;

  background-color: var(--secondary-color);
  border: 1px solid var(--accent-color);
  border-radius: 0.2rem;
  color: var(--text-color);
}

.std-button:is(.href) {
  background-color: transparent;
  border: none;
  padding: 0;

  text-decoration: underline;
  font-family: 'Courier New', Courier, monospace;
}

.std-button:not(:has(.icon)) {
  font-size: var(--text-size);
}
