#console-output {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  height: 100%;
  overflow: auto;

  font-family: 'Courier New', Courier, monospace;

  color: var(--text-color);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  cursor: pointer;
  border-radius: 10px;
  width: 5px;
}
