#home {
  display: flex;
  align-items: center;
  justify-content: center;
}

#github-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

#github-profile-img {
  border-radius: 100%;
  box-shadow: 0px 0px 0px 0.5rem var(--secondary-color);
  aspect-ratio: 1;
  width: 10rem;
}

#hireable {
  display: flex;
  gap: 0.5rem;
}
